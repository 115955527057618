<template>
  <div>
    <div class="container-fluid">
      <div class="row mb-3 mtp-20">
        <div class="col-5 mb-3">
          <div class="card">
            <div class="card-header py-3 d-flex flex-row align-item-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Datos para la simulación</h6>
            </div>
            <div class="card-body">
              <b-alert variant="danger" show v-if="!this.isClientPresent && this.isEmptyCurrentSimulation">
                <p>No se encontró un cliente para esta simulación, busca el enlace correcto en el trato de pipedrive</p>
              </b-alert>
              <client-information
                :guarantors="guarantors"
                :client-id="client.id || this.currentSimulation && this.currentSimulation.client.id"
                :client-name="client.full_name || this.currentSimulation && this.currentSimulation.client.name"
                :pipedrive-person-id="this.pipedrivePersonId || this.currentSimulation.client.pipedrive_id"
                :pipedrive-deal-id="this.pipedriveDealId || this.currentSimulation.pipedrive_id"
                v-if="pipedrivePersonId > 0 || pipedriveDealId > 0 || this.currentSimulation !== null" />
            </div>
          </div>
        </div>
        <div class="col-7 mb-3">
          <div>
            <div class="card">
              <div class="card-body">

                <b-form-group
                  id="input-group"
                  label-class="font-bold"
                  label-cols-sm="12"
                  label-cols-md="4"
                  content-cols-sm="12"
                  content-cols-md="8"
                  label="Costos asociados"
                  label-for="input-1"
                >
                  <b-input-group>
                    <b-form-select
                      v-model="selectedExtraCostName"
                      :options="this.extraCostsList"
                      value-field="name"
                      text-field="human_name"
                      placeholder="Tipo"
                    />
                    <b-input-group-append>
                      <b-input v-model="selectedExtraCostAmount" placeholder="Ingrese el costo"/>
                      <b-button block @click="addExtraCost" variant="primary">Agregar</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="row">
                  <div class="col-12 mt-3">
                    <ul class="extra-costs-list">
                      <li v-for="item in selectedExtraCosts.filter((ec) => ec._destroy !== 1 && !insuranceTranslations[ec.name])" :key="item.name">
                        {{ item.human_name }}: {{ item.amount | currency('$', 0, { thousandsSeparator: '.', decimalSeparator: ',' }) }}
                        <b-button
                          @click="removeExtraCost(item.name)"
                          variant="link"
                          size="sm"
                          title="Eliminar"
                          :disabled="!canManageExtraCosts"
                          v-if="canManageExtraCosts"
                        >
                          <i class="delete fas fa-times"></i>
                        </b-button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row font-bold py-5 px-3 flex">
                  <b-form-checkbox
                    class="mr-3"
                    v-model="lifeInsurance"
                    id="checkbox-1"
                  >
                    Seguro de Vida
                  </b-form-checkbox>
                  <!-- <b-form-checkbox
                    class="mr-3"
                    v-model="bikeInsurance"
                    id="checkbox-2"
                    v-show="hasSelectedBikeProductType"
                  >
                    Seguro de Moto
                  </b-form-checkbox> -->
                  <b-form-checkbox
                    v-if="!isLeasing && !canShowLienInsuranceDealer && !canShowPanelsLienInsurance && !canShowPanels50LienInsurance"
                    class="mr-3"
                    v-model="lienInsurance"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-3"
                  >
                    Seguro de Desgravamen
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="!isLeasing"
                    class="mr-3"
                    v-model="guarantorLienInsurance"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-22"
                  >
                    Seguro de Desgravamen complementario
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="!isLeasing"
                    class="mr-3"
                    v-model="stampDuty"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-4"
                  >
                    Impuesto de timbres y estampillas
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="liabilityInsurance"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-6"
                  >
                    Seguro de responsabilidad
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="fogape"
                    id="checkbox-8"
                  >
                    FOGAPE
                  </b-form-checkbox>

                  <!-- <unemployment-insurance-form
                    :unemployment-insurance-p="planUf6Insurance || planUf3Insurance"
                    :plan-uf3="planUf3Insurance"
                    :plan-uf6="planUf6Insurance"
                    @unemploymentInsuranceUpdated="unemploymentInsuranceUpdated"
                  ></unemployment-insurance-form> -->

                  <b-form-checkbox
                    class="mr-3"
                    v-model="severanceInsurance"
                    id="checkbox-19"
                  >
                    {{ t('activerecord.attributes.extra_cost.names.severance_insurance')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="pledgeAgreement"
                    v-show="hasSelectedBikeProductType || hasSelectedCarProductType"
                    id="checkbox-11"
                  >
                    {{ t('simulator.credit_simulator.extra_costs.pledge_agreement')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="prohibition"
                    v-show="hasSelectedBikeProductType || hasSelectedCarProductType"
                    id="checkbox-12"
                  >
                    {{ t('simulator.credit_simulator.extra_costs.prohibition')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="greenTax"
                    id="checkbox-13"
                  >
                    {{ t('activerecord.attributes.extra_cost.names.green_tax')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="circulationPermit"
                    id="checkbox-14"
                  >
                    {{ t('activerecord.attributes.extra_cost.names.circulation_permit')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="warranty"
                    v-show="canSelectWarrantyCost"
                    id="checkbox-15"
                  >
                    {{ t('simulator.credit_simulator.extra_costs.warranty')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="minorRepairs"
                    id="checkbox-16"
                  >
                    {{ t('simulator.credit_simulator.extra_costs.minor_repairs')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="digitalSignature"
                    id="checkbox-17"
                  >
                    {{ t('activerecord.attributes.extra_cost.names.digital_signature')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    class="mr-3"
                    v-model="totalLoss"
                    id="checkbox-18"
                  >
                    {{ t('simulator.credit_simulator.extra_costs.total_loss')}}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="!isLeasing && canShowLienInsuranceDealer"
                    class="mr-3"
                    v-model="lienInsuranceDealer"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-3"
                  >
                    Seguro de Desgravamen Dealer
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="!isLeasing && canShowPanelsLienInsurance && !canShowPanels50LienInsurance"
                    class="mr-3"
                    v-model="panelslienInsurance"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-21"
                  >
                    Seguro de Desgravamen de Paneles
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="!isLeasing && canShowPanels50LienInsurance"
                    class="mr-3"
                    v-model="panels50LienInsurance"
                    :disabled="!canManageExtraCosts"
                    id="checkbox-20"
                  >
                    Seguro de Desgravamen de Paneles
                  </b-form-checkbox>

                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <installments-form
              @installmentsUpdated="installmentsUpdated"
              :simulation-id="this.simulationId"
            />
          </div>
        </div>

        <credit-simulator-v2
          :client-id="client.id"
          :current-simulation="currentSimulation"
          :initial-installments="installments"
          :pipedrive-id="pipedriveDealId"
          :product-type="selectedProductType"
          :sim-model-color-preference="simModelColorPreferences"
          :selected-extra-costs="selectedExtraCosts"
          :fogape="fogape"
          @currentSimulationUpdated="updateCurrentSimulation"
          @hideSaveButton="hideSaveButton"
          @signDateUpdated="signDateUpdated"
          @simulationCreated="updateSimulationId"
          @simulationTypeUpdated="simulationTypeUpdated"
          @clearProducts="clearProducts"
          @updateProducts="updateProducts"
        />
      </div>

      <b-alert variant="danger" show v-if="!this.isClientPresent && this.isEmptyCurrentSimulation">
        <p>No se encontró un cliente para esta simulación, busca el enlace correcto en el trato de pipedrive</p>
      </b-alert>
      <b-alert variant="danger" show v-if="hasMinimalConditions && !this.isCalculated">
        <p>Debes volver a calcular la simulación, ya que cambiaste datos.</p>
      </b-alert>
      <commercial-executive-button
        v-if="hasMinimalConditions && this.isCalculated"
        :simulation-id="this.simulationId"
        :client="this.client"
        :pipedrive-person-id="this.pipedrivePersonId"
        :pipedrive-deal-id="this.pipedriveDealId"
      />
    </div>
  </div>
</template>

<script>
import InstallmentsForm from './InstallmentsForm.vue'
import CreditSimulatorV2 from '../credit/simulatorV2'
import CommercialExecutiveButton from './CommercialExecutiveButton.vue'
import ClientInformation from './ClientInformation.vue'
import calculatorMixin from '../mixins/calculatorV2'
import clientMixin from '../mixins/client'
import VehicleList from './VehicleSearchList.vue'
import VehicleModelsList from './VehicleModelsList.vue'
import { insuranceTranslations } from '../../constants/simulator'
import unemploymentInsuranceForm from './unemploymentInsuranceForm.vue'
import i18n from "../mixins/i18n"


export default {
  name: 'SimulatorFormV2',
  mixins: [calculatorMixin, clientMixin, i18n],
  components: {
    CreditSimulatorV2,
    CommercialExecutiveButton,
    ClientInformation,
    InstallmentsForm,
    VehicleList,
    VehicleModelsList,
    unemploymentInsuranceForm
  },
  props: [
    'simulationId',
    'userRole',
    'newProductType',
    'simModelColorPreference',
    'firstEditModeLoaded',
    'setFirstViewAsLoaded'
  ],
  data: function() {
    return {
      simulation: null,
      isCalculated: false,
      selectedProductType: this.newProductType,
      // there should be a better way to handle permissions,
      // like CASL and stuff. But this is enough at the moment.
      manageExtraCostsRoles: [
        'operations',
        'operations_boss',
        'admin'
      ],
      showModal: false,
      insuranceTranslations,
      simModelColorPreferences: this.simModelColorPreference
    }
  },
  mounted: async function() {
    this.$emit('updateCalculator');
    await this.getExtraCostsList()
    await this.setCurrentSimulation()
    this.extraCostsList = this.extraCostsList.filter((ec) => (ec.name !== 'lien_insurance' && ec.name !== 'taxes'))

    if(!_.isNil(this.selectedProductType) && !_.isNil(this.isEmptyCurrentSimulation)) {
      this.setProductType(this.selectedProductType)
    }
    this.setFirstViewAsLoaded()
  },
  watch: {

    newProductType: function(newValue, oldValue) {
      if(!_.isNil(oldValue) || this.isEmptyCurrentSimulation){
        this.setProductType(newValue)
      } else {
        this.selectedProductType = newValue
      }
    }
  },
  computed: {
    hasSelectedBikeProductType: function () {
      return this.selectedProductType?.credit_type === 'MOTO'
    },
    hasSelectedCarProductType: function() {
      return this.selectedProductType?.credit_type.includes('AUTO')
    },
    canManageExtraCosts: function() {
      return this.manageExtraCostsRoles.some(role => this.userRole.includes(role))
    },
    isEmptyCurrentSimulation: function() {
      return _.isNil(this.currentSimulation)
    },
    isClientPresent: function() {
      return !_.isNil(this.client.id) && this.client.id !== 0
    },
    hasMinimalConditions: function(){
      return this.isClientPresent && this.simulationId
    },
    hasSelectedProductType: function() {
      return !_.isNil(this.selectedProductType)
    },
    showAvailableModelsList: function() {
      return _.includes(['MOTO', 'AUTOMOTRIZ'], this.selectedProductType?.credit_type)
    },
    canSelectWarrantyCost: function() {
      return !this.selectedProductType?.name.toLowerCase().includes('usad')
    },
    canShowLienInsuranceDealer: function() {
      return this.selectedProductType?.name?.toLowerCase().includes('dealer') || this.selectedProductType?.credit_type.toLowerCase().includes('dealer')
    },
    canShowPanelsLienInsurance: function() {
      return this.selectedProductType?.name?.toLowerCase().includes('panel') || this.selectedProductType?.credit_type.toLowerCase().includes('panel')
    },
    canShowPanels50LienInsurance: function() {
      return this.selectedProductType?.name?.toLowerCase().includes('+50') || this.selectedProductType?.credit_type.toLowerCase().includes('panel')
    }
  },
  methods: {
    setProductType: function(productType) {
      this.selectedProductTypeId = productType.id
      this.selectedProductType = productType
      /**
       * omitAddProductTypeExtraCosts
       * This constant is used to show the extra cost values stored by the user previously.
       */
      const omitAddProductTypeExtraCosts = this.simulationId && !this.firstEditModeLoaded
      if(!omitAddProductTypeExtraCosts) this.addProductTypeExtraCosts()
    },
    hideSaveButton: function(value) {
      this.isCalculated = value
    },
    isLeasing: function() {
      return this.simulationType === 'leasing'
    },
    setSelectedProductType: function(value) {
      this.selectedProductType = this.productTypesList.filter(productType => productType.id === value)[0]
    },
    clearProducts: function() {
      this.$emit('clearProducts')
    },
    updateProducts: function(products) {
      this.$emit('updateProducts', products)
    }
  }
}

</script>
